@use 'sass:color';
@use 'sass:math';
@use 'sass:meta';

// Set bootstrap break points
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 782px, // overridden with the same width for laraberg. don't remove the others or it will break // TODO: remove later
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

/**
 * Frontend styles via Bootstrap Framework.
 */
@import 'bootstrap';

/**
 * Icons via Font Awesome.
 */
$fa-font-path: '/fonts';
@import 'fontawesome/fontawesome';
@import 'fontawesome/solid';
@import 'fontawesome/brands';
@import 'fontawesome/regular';

/**
 * Helper function to auto scale default colors for tailwindcss variations
 */
@function cms-color-scale($color, $variation) {
    @if $variation == '' {
        @return $color;
    }

    // $variation starts with a minus so we'll add to subtract
    // multiply the saturation with a negative value at the end to make lighter colors less saturated
    @return scale-color(
            $color,
        $lightness: math.div((500 + $variation), 1000.0) * 100%,
        $saturation: math.div((500 + $variation), 1000.0) * -50%
    );
}

/**
 * Custom styles.
 */
/* stylelint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        @each $value in ['', -50, -100, -200, -300, -400, -500, -600, -700, -800, -900] {
            --light#{'' + $value}: #{cms-color-scale(#f8f9fa, $value)};
            --dark#{'' + $value}: #{cms-color-scale(#343a40, $value)};

            --button-primary#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};
            --button-secondary#{'' + $value}: #{cms-color-scale(#9ba2ae, $value)};
            --button-success#{'' + $value}: #{cms-color-scale(#22c55e, $value)};
            --button-danger#{'' + $value}: #{cms-color-scale(#b91c1c, $value)};
            --button-warning#{'' + $value}: #{cms-color-scale(#f59e0b, $value)};
            --button-light#{'' + $value}: #{cms-color-scale(#f8f9fa, $value)};
            --button-dark#{'' + $value}: #{cms-color-scale(#212529, $value)};

            --button-primary-hover#{'' + $value}: #{cms-color-scale(#2dbbe0, $value)};
            --button-secondary-hover#{'' + $value}: #{cms-color-scale(#a3aeb9, $value)};
            --button-success-hover#{'' + $value}: #{cms-color-scale(#28a745, $value)};
            --button-warning-hover#{'' + $value}: #{cms-color-scale(#d59c21, $value)};
            --button-danger-hover#{'' + $value}: #{cms-color-scale(#a71919, $value)};
            --button-info-hover#{'' + $value}: #{cms-color-scale(#17a2b8, $value)};
            --button-light-hover#{'' + $value}: #{cms-color-scale(#edeeee, $value)};
            --button-dark-hover#{'' + $value}: #{cms-color-scale(#343a40, $value)};

            --button-primary-text#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-secondary-text#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-success-text#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-danger-text#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-warning-text#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-light-text#{'' + $value}: #{cms-color-scale(#212529, $value)};
            --button-dark-text#{'' + $value}: #{cms-color-scale(#fff, $value)};

            --button-primary-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-secondary-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-success-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-danger-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-warning-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --button-light-text-hover#{'' + $value}: #{cms-color-scale(#212529, $value)};
            --button-dark-text-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --auth-link-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --auth-card-link-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --auth-footer-link-hover#{'' + $value}: #{cms-color-scale(#fff, $value)};

            --ucp-body-bg#{'' + $value}: #{cms-color-scale(#fff, $value)};
            --ucp-text#{'' + $value}: #{cms-color-scale(#000, $value)};

            --auth-input-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};
            --ucp-input-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};
            --acp-input-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};
            --auth-checkbox-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};
            --ucp-checkbox-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};
            --acp-checkbox-bg#{'' + $value}: #{cms-color-scale(#f3f4f6, $value)};

            --auth-input-error#{'' + $value}: #{cms-color-scale(#e3342f, $value)};
            --ucp-input-error#{'' + $value}: #{cms-color-scale(#e3342f, $value)};
            --acp-input-error#{'' + $value}: #{cms-color-scale(#e3342f, $value)};

            --auth-checkbox-checked#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};
            --ucp-checkbox-checked#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};
            --acp-checkbox-checked#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};

            --auth-input-focus#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};
            --ucp-input-focus#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};
            --acp-input-focus#{'' + $value}: #{cms-color-scale(#1ca8d2, $value)};

            --auth-input-text#{'' + $value}: #{cms-color-scale(#121318, $value)};
            --ucp-input-text#{'' + $value}: #{cms-color-scale(#121318, $value)};
            --acp-input-text#{'' + $value}: #{cms-color-scale(#121318, $value)};

            --acp-body-bg#{'' + $value}: #{cms-color-scale(#ffffff, $value)};
            --acp-text#{'' + $value}: #{cms-color-scale(#000, $value)};

        }

        --auth-input-placeholder: #6b7280;
        --ucp-input-placeholder: #6b7280;
        --acp-input-placeholder: #6b7280;

        --auth-body-bg: #0c0c0c;
        --auth-card-bg: #777;
        --auth-card-border: #777;
        --auth-card-text: #fff;
        --auth-card-link: #ddd;
        --auth-footer-bg: #777;
        --auth-footer-text: #fff;
        --auth-footer-link: #ddd;
        --auth-text: #fff;
        --auth-link: #dcdcdc;

        --ucp-link: #000;
        --ucp-link-hover: #0d0d0d;

        --header-background: #fff;
        --header-text-color: #212529;
        --header-link-color: #212529;
        --header-link-hover-color: #d3d3d3;
        --header-border-color: #e2e8f0;

        --footer-background: #1ca8d2;
        --footer-text-color: #fff;
        --footer-link-color: #fff;
        --footer-link-hover-color: #d3d3d3;

        --navbar-background: #fff;
        --navbar-popout-background: #fff;
        --navbar-text-color: #000;
        --navbar-link-color: #000;
        --navbar-link-hover-color: #1ca8d2;
        --navbar-popout-link-color: #000;
        --navbar-popout-link-hover-color: #1ca8d2;

        --acp-link: #1c1c1c;
        --acp-link-hover: #0d0d0d;

        --acp-header-bg: #343a40;
        --acp-header-text: #fff;
        --acp-header-link: #ddd;
        --acp-header-link-hover: #fff;

        --acp-nav-bg: #f8f9fa;
        --acp-nav-text: #6b7280;
        --acp-nav-link: #6b7280;
        --acp-nav-link-hover: #fff;
        --acp-nav-link-hover-bg: #1e40ae;

        --acp-footer-bg: #000;
        --acp-footer-text: #fff;
        --acp-footer-link: #ddd;
        --acp-footer-link-hover: #fff;

        --font-size-default: 0.875rem;
        --font-size-small: 0.75rem;
        --font-size-medium: 1rem;
        --font-size-large: 1.25rem;
        --font-size-extra-large: 1.5rem;
        --font-size-huge: 2rem;
        --font-size-headline: 3rem;
        --font-size-subhead: 2.5rem;
        --font-size-section-header: 1.5rem;
        --font-size-section-subheader: 1.5rem;
        --font-size-site-header: 3rem;
        --font-size-footer-title: 1.25rem;
        --font-size-footer: 0.875rem;

    }
}

/* stylelint-enable */

/*
 * Classes for column-count
 */
@each $columnCount in [2, 3, 4] {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .cols#{$infix}-#{$columnCount} {
                column-count: #{$columnCount};
            }
        }
    }
}

.required-indicator:after {
    content: " *";
    color: #ff0000;
}

.form-group td {
    padding: 10px 0;
}

.form-group td.label {
    vertical-align: text-top;
}

.form-group td:first-child {
    width: 30%;
}

.form-group td:last-child {
    width: 70%;
}

@media (max-width: 1000px) {
    .navbar-collapse {
        display: none;
    }
    .navbar-toggler[aria-expanded="true"] ~ .navbar-collapse {
        display: block;
    }
}

$image-sizes: 40px, 80px, 120px, 160px, 200px;

@each $size in $image-sizes {
    .img-#{$size} {
        max-width: $size;
        max-height: $size;
    }
}

$color-ui-border: #cbcbcb;
$spacer: 10px;
$map-height: 200px;
$swatch-width: 32px;
$swatch-space: 4px;
$swatches-width: (6 * $swatch-width) + (5 * $swatch-space);
$map-width: $swatches-width;
$eyedropper-width: $swatch-width * 2 + $swatch-space;
$window-width: $swatches-width + $eyedropper-width + $spacer * 3;
$spectrum-cursor-width: 30px;

.color-picker-panel .panel-row {
    position: relative;
    margin: 0 10px 10px;

    &:first-child {
        margin-top: 10px;
        margin-bottom: $spacer - $swatch-space;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.color-picker-panel .panel-header {
    text-align: center;
}

.color-picker-panel .swatches {
    display: grid;
    grid-template-columns: repeat(6, $swatch-width);
    grid-gap: $swatch-space;
}

.color-picker-panel .swatch {
    width: $swatch-width;
    height: $swatch-width;
    background: #ccc;
    border-radius: 4px;
    margin-left: 4px;
    margin-bottom: 4px;
    box-sizing: border-box;
    border: 2px solid $color-ui-border;
    cursor: pointer;

}

.color-picker-panel .color-cursor {
    background: #ccc;
    box-sizing: border-box;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    transition: all .2s ease;

    &.dragging {
        transition: none;
    }

    &.spectrum-cursor {
        width: $spectrum-cursor-width;
        height: $spectrum-cursor-width;
        margin-left: - calc($spectrum-cursor-width / 2);
        margin-top: - calc($spectrum-cursor-width / 2);
        top: 0;
        left: 0;
    }

    &.hue-cursor {
        top: 0;
        left: 50%;
        height: 20px;
        width: 20px;
        margin-top: -10px;
        margin-left: -10px;
        pointer-events: none;
    }

    &.alpha-cursor {
        top: 0;
        left: 50%;
        height: 20px;
        width: 20px;
        margin-top: -10px;
        margin-left: -10px;
        pointer-events: none;
    }
}

.color-picker-panel .spectrum-map {
    position: relative;
    width: $map-width;
    height: $map-height;
    overflow: hidden;
}

.color-picker-panel .spectrum-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;
    cursor: pointer;
}

.color-picker-panel .hue-map {
    position: absolute;
    top: calc($spacer / 2);
    bottom: calc($spacer / 2);
    right: calc(calc($eyedropper-width / 1.25) - calc($spacer / 2));
    width: $spacer;
}

.color-picker-panel .hue-canvas {
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ccc;
    cursor: pointer;
}

.color-picker-panel .alpha-map {
    position: absolute;
    top: calc($spacer / 2);
    bottom: calc($spacer / 2);
    right: calc(calc($eyedropper-width / 1.5) - calc($eyedropper-width / 2) - calc($spacer / 2));
    width: $spacer;
}

.color-picker-panel .alpha-canvas {
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    cursor: pointer;
}
.color-picker-panel .mode-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: $eyedropper-width;
    height: $eyedropper-width;
}
.color-picker-panel .button {
    background: var(--button-primary);
    border: 0;
    border-radius: 4px;
    color: var(--button-primary-text);
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    outline: none;
    cursor: pointer;
    padding: 4px;

    &.add-swatch {
        display: block;
        padding: 6px;
        width: 200px;
        margin: 10px auto 0;
    }


    &.eyedropper-toggle {
        position: absolute;
        top: 0;
        right: 0;
        width: calc($eyedropper-width - 5px);
        height: calc($eyedropper-width - 5px);
    }
}

.color-picker-panel .value-fields {
    display: none;
    align-items: center;

    &.active {
        display: flex;
    }
}

.color-picker-panel .rgb-fields {
    .field-group {
        display: flex;
        align-items: center;
    }

    .field-input {
        width: 42px;
        margin-right: $spacer;
    }
}

.color-picker-panel .hex-field {
    .field-input {
        width: 170px;
    }
}

.color-picker-panel .color-indicator {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: #ccc;
}

.color-picker-panel .rgb-fields input::-webkit-outer-spin-button,
.color-picker-panel .rgb-fields input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.color-preview__element--active {
    border: 2px dashed #f00;
}
.color-preview__element {
    cursor: pointer;
}

.tw-toggle {
    display: flex;
    padding: 3px 5px;
    position: relative;
    border: 2px solid #95A5A6;
    height: 30px;
    width: 90px;
    flex-direction: row;
}

.tw-toggle label {
    text-align: center;
    font-family: sans-serif;
    color: #95A5A6;
    position: absolute;
    z-index: 2;
    padding-left: 7px;
    font-size: 15px;
    cursor: pointer;
}

.tw-toggle > label:nth-of-type(1) {
    left: 0;
}
.tw-toggle > label:nth-of-type(2) {
    left: 30px;
}
.tw-toggle > label:nth-of-type(3) {
    left: 60px;
}


.tw-toggle input {
    position: absolute;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
}
.tw-toggle > input:nth-of-type(1) {
    left: 0;
}
.tw-toggle > input:nth-of-type(2) {
    left: 30px;
}
.tw-toggle > input:nth-of-type(3) {
    left: 60px;
}

.tw-toggle span {
    height: 27px;
    width: 28px;
    line-height: 15px;
    background:#fff;
    display:block;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
}

.tw-toggle input[value="1"]:checked ~ span{
    background:#e74c3c;
    left:0;
    color:#fff;
}

.tw-toggle input[value="2"]:checked ~ span{
    background:#27ae60;
    left: 60px;
}
.tw-toggle input[value="0"]:checked ~ span{
    background:#95A5A6;
    left: 30px;
}

.tw-toggle input[value="1"]:checked + label,.tw-toggle input[value="2"]:checked + label, .tw-toggle input[value="0"]:checked + label{
    color:#fff;
}

.pencil {
    display: block;
    width: 10em;
    height: 10em;
}

.pencil__body1,
.pencil__body2,
.pencil__body3,
.pencil__eraser,
.pencil__eraser-skew,
.pencil__point,
.pencil__rotate,
.pencil__stroke {
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.pencil__body1,
.pencil__body2,
.pencil__body3 {
    transform: rotate(-90deg);
}

.pencil__body1 {
    animation-name: pencilBody1;
}

.pencil__body2 {
    animation-name: pencilBody2;
}

.pencil__body3 {
    animation-name: pencilBody3;
}

.pencil__eraser {
    animation-name: pencilEraser;
    transform: rotate(-90deg) translate(49px,0);
}

.pencil__eraser-skew {
    animation-name: pencilEraserSkew;
    animation-timing-function: ease-in-out;
}

.pencil__point {
    animation-name: pencilPoint;
    transform: rotate(-90deg) translate(49px,-30px);
}

.pencil__rotate {
    animation-name: pencilRotate;
}

.pencil__stroke {
    animation-name: pencilStroke;
    transform: translate(100px,100px) rotate(-113deg);
}

/* Animations */
@keyframes pencilBody1 {
    from,
    to {
        stroke-dashoffset: 351.86;
        transform: rotate(-90deg);
    }

    50% {
        stroke-dashoffset: 150.8;
        /* 3/8 of diameter */
        transform: rotate(-225deg);
    }
}

@keyframes pencilBody2 {
    from,
    to {
        stroke-dashoffset: 406.84;
        transform: rotate(-90deg);
    }

    50% {
        stroke-dashoffset: 174.36;
        transform: rotate(-225deg);
    }
}

@keyframes pencilBody3 {
    from,
    to {
        stroke-dashoffset: 296.88;
        transform: rotate(-90deg);
    }

    50% {
        stroke-dashoffset: 127.23;
        transform: rotate(-225deg);
    }
}

@keyframes pencilEraser {
    from,
    to {
        transform: rotate(-45deg) translate(49px,0);
    }

    50% {
        transform: rotate(0deg) translate(49px,0);
    }
}

@keyframes pencilEraserSkew {
    from,
    32.5%,
    67.5%,
    to {
        transform: skewX(0);
    }

    35%,
    65% {
        transform: skewX(-4deg);
    }

    37.5%,
    62.5% {
        transform: skewX(8deg);
    }

    40%,
    45%,
    50%,
    55%,
    60% {
        transform: skewX(-15deg);
    }

    42.5%,
    47.5%,
    52.5%,
    57.5% {
        transform: skewX(15deg);
    }
}

@keyframes pencilPoint {
    from,
    to {
        transform: rotate(-90deg) translate(49px,-30px);
    }

    50% {
        transform: rotate(-225deg) translate(49px,-30px);
    }
}

@keyframes pencilRotate {
    from {
        transform: translate(100px,100px) rotate(0);
    }

    to {
        transform: translate(100px,100px) rotate(720deg);
    }
}

@keyframes pencilStroke {
    0% {
        stroke-dashoffset: 439.82;
        transform: translate(100px,100px) rotate(-113deg);
    }

    50% {
        stroke-dashoffset: 164.93;
        transform: translate(100px,100px) rotate(-113deg);
    }

    75%, 100% {
        stroke-dashoffset: 439.82;
        transform: translate(100px,100px) rotate(112deg);
    }
}
.form-container__sign-app-buttons {
    display: flex;
    column-gap: 24px;
    align-items: center;
    margin-top: 20px;
}

.form-container__sign-app-buttons a {
    justify-content: center;
    min-width: 110px;
    height: 40px;
    border: none;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 1px #b7b7b7;
    display: flex;
    align-items: center;
    color: #4b4b4b;
    font-size: 13px;
    column-gap: 15px;
    cursor: pointer;
}

.form-container__sign-app-buttons a:active {
    background: #eeeeee;
}

.form-container__sign-app-buttons a:hover:not(:active) {
    outline: none;
    -webkit-box-shadow: inset 0 0 3px 1px var(--button-primary-hover);
    box-shadow: inset 0 0 3px 1px var(--button-primary-hover);
}
.form-container__sign-app-buttons svg {
    width: 22px;
    height: auto;
}

/* Alpine JS */
[x-cloak] {
    display: none !important;
}
